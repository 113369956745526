
import { Component, Vue } from "vue-property-decorator";
import Header from '@/components/header.vue'; // @ is an alias to /src
import SidebarFinance from '@/components/sidebar-finance.vue'; // @ is an alias to /src
import BuiltinService from '@/services/buitin-service';
import accountService from '@/services/account-service';
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils";

@Component({
  components: {
    Header,
    SidebarFinance,
    Loader,
    PopUp
  },
})
export default class OpeningBalance extends Vue {
  public import_file: any = null;

  public items = [
    {
      identity: 0,
      account: '',
      transaction: '',
      account_title: '',
      account_code: '',
      cost_center: '',
      description: '',
      debit: 0.00,
      credit: 0.00,
      amount: '',
      reference_no: '',
      request_no: '',
      comments: '',
      po_no: ""
    }
  ];
  public import_response = "";
  selected_project: any = {};
  option_accounts: any = [];
  branch_options: any = [];
  options_project: any = [];
  trans: any = {};
  auth: any = {};
  sum_d: number = 0
  sum_c: number = 0;
  total = 0.00;
  transaction = ''
  sum_transaction = 0;
  index = 0;
  loading = false;

  error: any = "";
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end
  submit_import() {
    const isValidFile = this.validateFile(this.import_file);
    if(!isValidFile){
      this.showModal("Invalid file. Please upload a valid file by download the sample.",[],"error")
    }else {
    this.import_response = "Please wait, it will take time.";
    this.loading = true;
    this.import_response = "";
    if (this.import_file != null) {
      accountService.bulkImportOpeningBalances(this.import_file)
        .then((response) => {
          this.import_response = "Uploaded.";
          this.loading = false;
        })
        .catch((e) => {
          this.import_response = "Error occured while uploading.";
          this.loading = false;
          console.log(e);
        });


    } else {
      this.import_response = "Please upload the file";
      this.loading = false;
    }
  }
  }

  getaccounts() {
    BuiltinService.getauth()
      .then((response) => {
        this.auth = response.data[0];
      })
      .catch((e) => {
        console.log(e);
      });
    BuiltinService.getproject()
      .then((response) => {
        this.options_project = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
    // accountService.getAccounts()
    // .then((response) => {  
    //   this.option_accounts = response.data.result;      
    //   })
    // .catch((e) => {
    //   console.log(e);
    // });

  }
  addRow() {

    this.items.push({
      identity: this.items.length + 1,
      account: '',
      transaction: '',
      account_code: '',
      account_title: '',
      cost_center: '',
      description: '',
      debit: 0.00,
      credit: 0.00,
      amount: '',
      reference_no: '',
      request_no: '',
      comments: '',
      po_no: '0',

    })

  }
  save() {
    this.error = ""
    const errorListArray = utils.validateArray(this.items, ['account_title', 'account_code', 'cost_center', 'reference_no', 'request_no', 'description',]);
    if (errorListArray.length > 0 || !this.selected_project || Object.keys(this.selected_project).length === 0) {
      this.error = utils.generateErrorMessage(errorListArray.concat((!this.selected_project || Object.keys(this.selected_project).length === 0) ? 'Branch' : ''));
    }
    else {
      let sum_c = 0;
      let sum_d = 0;
      try {
        for (var i = 0; i < this.items.length; i++) { sum_c += (+this.items[i]['credit']) }
        for (var i = 0; i < this.items.length; i++) { sum_d += (+this.items[i]['debit']) }

        // this.sum_transaction = sum_c - sum_d
        // if (sum_c == sum_d) {
        this.trans['items'] = this.items;
        this.trans['branch'] = this.selected_project;
        this.trans['code'] = '0' + (this.items.length + 1).toString()
        this.trans["trans_type"] = "Opening Balance";
        accountService.getTransaction(this.trans, null)
          .then((response) => {
            this.trans = {};
            this.items = [];
            this.selected_project = {};
            this.index = 0;
            this.addRow();
            this.error = "";
            this.transaction = 'Transaction Generated ' + response.data.code;
            // alert(this.transaction)
            this.showModal("Generated Successfully" + response.data.code, [], "success")
          })
          .catch((e) => {
            if (e.response.data.error) {
              this.showModal(e.response.data.error, [], "error")
            } else {
              console.log('Unexpected error:', e);
              this.showModal('An unexpected error occurred', [], "error");
            }
          });

        // } else {
        //   // alert('Sum must be equal')
        //   this.showModal("Sum must be equal", [], "error")
        // }
      } catch (e) {
        console.log('Unexpected error:', [], e);
        this.showModal('An unexpected error occurred', [], "error");
      }
    }
  }

  get_parent_account(data: any) {

    this.items[this.index].account_title = data.title
    this.items[this.index].account_code = data.code
    this.items[this.index].account = data.id
    this.option_accounts = [];
  }

  search_parent_account(title: any, index: any) {
    this.index = index
    if (title.length > 2) {
      accountService.getByTitle(title)
        .then((response) => {
          this.option_accounts = response.data.result;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.option_accounts = [];
    }
  }

  mounted() {

    let current = new Date();
    let start_date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
    this.trans['date'] = start_date;
    this.getaccounts();
  }
  deleteRow(index: any) {
    this.items.splice(index, 1);
  }
  clearRow(index: any) {
    const item = this.items[index];
    item.account = '';
    item.transaction = '';
    item.account_title = '';
    item.account_code = '';
    item.cost_center = '';
    item.description = '';
    item.debit = 0.00;
    item.credit = 0.00;
    item.amount = '';
    item.reference_no = '';
    item.request_no = '';
    item.comments = '';
  }
  validateFile(file:any) {
      // Check if a file is selected
      if (!file) {
        return null;
      }
      // Get the file name
      const fileName = file.name;

      // Define allowed file extensions (PDF and Excel)
      const allowedExtensions = ['.xls', '.xlsx','.ods','.xlsm,','.xlsb','.xltx','.xltm','.xlam'];
      // Check if the file has an allowed extension
      const isValid = allowedExtensions.some(extension => fileName.toLowerCase().endsWith(extension));
      return isValid ? true : false;
    }
}
